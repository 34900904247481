<template>
  <div class="info_wrap">
    <el-form label-width="92px">
      <el-row :gutter="10">
        <el-col :span="5">
          <el-form-item label="试卷ID：">
            {{list.edu_paper_id}}
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="试卷名称：">
            {{list.paper_name}}
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="试卷科目：">
            {{list.subject_name}}
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="试卷地区：">
            {{list.dis_name}}
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item label="试卷年份：">
            {{list.str_post?list.str_post.questionYear:''}}
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="试卷来源：">
            {{list.source_name}}
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item label="试卷总分：">
            {{list.paper_score}}
          </el-form-item>
        </el-col>
      </el-row>

    </el-form>
    <PaperBtn :name="list.paper_name"
              :isCollect="list.is_collect" />
    <div v-if="list.note"
         style="margin:14px">
      备注：{{list.note}}
    </div>
    <BASETOPICCOMMON ref="BASETOPICCOMMONREF"
                     :list="list.questions"
                     :showOpertion="changeAble">

    </BASETOPICCOMMON>
    <Dialog ref="DialogRef" />

    <sortDialog ref="sortDialogRef" />
    <!-- <div class="btn"
         v-if="this.list.paper_enable !== 1">
      <el-button type="primary"
                 @click="submitAll">提交试卷</el-button>
    </div> -->

    <scrollTo ref="scrollToRef"
              :list="childredList" />
  </div>
</template>

<script>
import tipicBody from '@/components/topic/index.vue'
import Dialog from '@/components/changeTopic/index.vue'
import sortDialog from '../../../../components/changeTopic/sort.vue'
import scrollTo from '@/components/scrollTo'
import BASETOPICCOMMON from '@/components/BASE_PAPER_COMMON/index.vue'
import PaperBtn from '@/components/paperBtn'
export default {
  data () {
    return {
      recordEduPaper: {},
      list: [],
      isActive: 0,
      domList: [],
      childredList: 0,
      changeAble: false

    }
  },
  components: {
    tipicBody, Dialog, sortDialog, scrollTo, BASETOPICCOMMON, PaperBtn
  },
  mounted () {
    if (this.$route.query.module == 4 || this.$route.query.module == 5 || this.$route.query.module == 6) {
      this.changeAble = true
    }
    this.getInfo()
    document.body.background = '#F6F6F6'
    document.getElementsByClassName('view_warp')[0].style.background = '#F6F6F6'

  },
  beforeDestroy () {
    document.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    getInfo () {
      this.$http({
        url: '/api/v1/combination/czb_paper_detail',
        method: 'get',
        params: {
          edu_paper_id: this.$route.query.edu_paper_id
        }
      }).then(res => {
        this.recordEduPaper = res.data.recordEduPaper
        this.list = res.data
        setTimeout(() => {
          var oFixed = document.getElementsByClassName('yuan')
          this.childredList = oFixed.length
        }, 1000);
      })
    },
    check (row) {
      var edu_paper_detail_id = []
      let replaceUrl = '',
        submitUrl = ''
      if (this.$route.query.module == 6) {
        replaceUrl = '/api/v1/combination/rule_replace'
        submitUrl = '/api/v1/combination/rule_replace_done'
      } else {
        replaceUrl = '/api/v1/combination/chapter_replace'
        submitUrl = '/api/v1/combination/chapter_replace_done'
      }
      if (row.question_children) {
        row.question_children.forEach(item => {
          edu_paper_detail_id.push(item.edu_paper_detail_id)
        });
      } else {
        edu_paper_detail_id.push(row.edu_paper_detail_id)
      }
      let form = {
        edu_paper_id: this.$route.query.edu_paper_id,
        question_id: row.question_id
      }
      if (this.$route.query.module == 6) {
        form.answer_id = row.answer_id
      }
      this.$refs.DialogRef.edu_paper_detail_id = edu_paper_detail_id
      this.$refs.DialogRef.replaceUrl = replaceUrl
      this.$refs.DialogRef.submitUrl = submitUrl
      this.$refs.DialogRef.form = form
      this.$refs.DialogRef.dialogVisible = true
    },
    submitAll () {
      this.$http({
        url: '/api/v1/combination/test_paper_submit',
        method: 'post',
        data: {
          edu_paper_id: this.$route.query.edu_paper_id
        }
      }).then(res => {
        this.$notify({
          title: '提示',
          message: '提交试卷成功!',
          type: 'success'
        });
        this.$router.push({ path: '/testPapercomposition/chapter' })
      })
    },
    sort (item) {
      let form = JSON.parse(JSON.stringify(item))
      // form.new_serial = form.serial_in_paper
      form.serial_in_paper = form.answer_id
      form.edu_paper_id = this.$route.query.edu_paper_id
      this.$refs.sortDialogRef.form = form
      this.$refs.sortDialogRef.sort()
      this.$refs.sortDialogRef.dialogVisible = true
    },
    toMaodian (index) {
      this.isActive = index
      let anchorElement = document.getElementById('maodian' + index);
      if (anchorElement) {
        anchorElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.info_wrap {
  margin-bottom: 20px;
}
::v-deep .view_warp[data-v-fae5bece] {
  background: #f6f6f6;
}
.sort {
  cursor: pointer;
  margin-right: 10px;
  color: #409eff;
}
.check {
  cursor: pointer;
  color: #409eff;
}
.btn {
  margin-top: 10px;
  width: 100%;
  text-align: center;
  height: 30px;
  line-height: 30px;
  padding-bottom: 30px;
}
::v-deep .el-loading-mask {
  z-index: 2010 !important;
}
.form_warp {
  background: #f6f6f6;
  .el-form-item {
    margin-bottom: 0px;
    color: #000000;
    font-weight: bold;
    .el-form-item__label {
      color: #999999;
    }
  }
}
.tipicBody_class {
  margin-top: 20px;
  position: relaive;
  margin-right: 130px;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
}

::v-deep .el-form-item__label {
  font-weight: bold;
}
// .nowarp {
//   display: flex;
//   .oper_btn {
//     padding: 0 15px;
//     font-size: 15px;
//     height: 30px;
//     line-height: 30px;
//     border-radius: 22px;
//     color: white;
//     display: inline-block;
//     margin-right: 15px;
//   }
// }

// @media screen and (min-width: 1200px) {
//   .nowarp {
//     display: flex;
//     justify-content: flex-end;
//     .oper_btn {
//       padding: 0 9px;
//       font-size: 15px;
//       height: 30px;
//       line-height: 30px;
//       border-radius: 22px;
//       color: white;
//       display: inline-block;
//     }
//  }
// }
</style>